import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Production Line" } }, [_c("select-input", { key: "FacilityId", attrs: { "form-item": "", "rules": "required", "label": "Facility", "reference": "line-performance.common.local-facilities", "source": "id", "source-label": _vm.parseFacilityLabel, "value": _vm.facility.value, "placeholder": "Select Facility", "span": 12, "disabled": true, "on-select-change": _vm.onFacilityChange } }), _c("select-input", { key: "AreaId", attrs: { "form-item": "", "rules": "required", "label": "Department", "data-source": _vm.listAreas, "source": "id", "source-label": "name", "value": _vm.area.value, "placeholder": "Select Department", "span": 12 } }), _c("text-input", { key: "Code", attrs: { "form-item": "", "rules": "required", "label": "Code", "placeholder": "Type Code", "span": 12 } }), _c("text-input", { key: "Description", attrs: { "form-item": "", "label": "Description", "placeholder": "Type Description", "span": 12 } })], 1);
};
var staticRenderFns$1 = [];
var CreateProductionLine_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const __vue2_script$1 = {
  name: "CreateProductionLine",
  inject: ["crud", "area", "facility", "getListAreas"],
  data() {
    return {
      facilityId: this.facility.value
    };
  },
  computed: {
    listAreas() {
      return this.getListAreas();
    }
  },
  created() {
    this.storeValue("facilityID", this.facility.value);
    this.storeValue("areaID", this.area.value);
  },
  methods: {
    onFacilityChange(value) {
      this.facilityId = value;
    },
    storeValue(resourceKey, value) {
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    parseFacilityLabel(record) {
      if (record.description) {
        return record.name + " " + record.description;
      }
      return record.name;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateProductionLine = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "line-performance.common.local-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.common.local-areas", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.productionlines", "api-url": _vm.apiUrl, "redirect-route": "/line-performance/production-line" } }, [_c("create-production-line")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateProductionLine
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
